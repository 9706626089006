/* @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap'); */

/* Указываем box sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
    padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
    list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
    max-width: 100%;
    display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article>*+* {
    margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
    font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

/* scroll bar styles */
body,
.plant_chip_list_block,
.withScroll {
    scrollbar-width: thin;
    /* "auto" или "thin"  */
    /* scrollbar-color: rgba(2, 142, 74, 1) rgba(255, 255, 255, 0.8); */
    scrollbar-color: rgb(63, 64, 64) rgba(255, 255, 255, 0.8);
    /* плашка скролла и дорожка */
}

body::-webkit-scrollbar,
.plant_chip_list_block::-webkit-scrollbar,
.withScroll::-webkit-scrollbar {
    width: 12px;
    /* ширина scrollbar */
}

body::-webkit-scrollbar-track,
.plant_chip_list_block::-webkit-scrollbar-track,
.withScroll::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 1);
    /* цвет дорожки */
}

body::-webkit-scrollbar-thumb,
.plant_chip_list_block::-webkit-scrollbar-thumb,
.withScroll::-webkit-scrollbar-thumb {
    background-color: rgba(63, 64, 64, 0.3);
    /* цвет плашки */
    border-radius: 20px;
    /* закругления плашки */
    border: 4px solid rgba(255, 255, 255, 1);
    /* padding вокруг плашки */
}

.root {
    min-height: 100%;
}

footer {
    flex: 0 0 auto;
}

.cut_out_text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}


.filtered-plant {
    background-color: #e6e6fa62;
}

.col {
    min-height: 50px;
}

.result-hover:hover * {
    background-color: antiquewhite;
    cursor: pointer;
}

.two_line_container {
    height: 3.9em;
    width: 100%
}

.exact_two_line_with_dot * {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1.3em;
}

.link_text {
    cursor: pointer
}

.link_text:hover {
    color: #d0c7b6;
}

.treeline,
.treeline ul,
.treeline li {
    margin: 0;
    padding: 0;
    line-height: 1.2;
    list-style: none;
}

.treeline ul {
    margin: 0 0 0 15px;
    /* отступ вертикальной линии */
}

.treeline>li:not(:only-child),
.treeline li li {
    position: relative;
    padding: 3px 20px 0 0;
    /* отступ текста */
}

/* Стиль вертикальной линии */
.treeline li:not(:last-child) {
    border-right: 1px solid #ccc;
}

/* Стили горизонтальной линии*/
.treeline li li:before,
.treeline>li:not(:only-child):before {
    content: "";
    position: absolute;
    top: 2;
    right: 0;
    width: 1.1em;
    height: .7em;
    border-bottom: 1px solid #ccc;
}

/* Вертикальная линия последнего пункта в списка */
.treeline li:last-child:before {
    width: calc(1.1em - 1px);
    height: 1em;
    position: absolute;
    top: -2px;
    border-right: 1px solid #ccc;
}

.hidden {
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
}

.with_hover {
    cursor: pointer;
    transition: all .2s ease;
    outline-color: rgb(208 199 182 / 2%);
}

.with_hover:hover {
    outline: 1px solid rgb(208 199 182 / 80%);
    box-shadow: 5px 5px 5px rgb(208 199 182 / 40%), -5px 5px 5px rgb(208 199 182 / 40%);
}

@media only screen and (max-width: 600px) {
    #root {
    padding: 15px;
}
}
    @media only screen and (max-width: 400px) {
    #root {
        padding: 5px;
    }
}